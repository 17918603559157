<template>
  <div class="wrapper">
    <div class="userinfo">
      <el-image
        style="width: 70px; height: 70px; border-radius: 5px; margin-right: 10px"
        :src="detail.userInfo.face"
        :preview-src-list="[detail.userInfo.face]"
        fit="contain"
      >
        <div style="line-height: 70px; text-align: center; background: #f5f7fa" slot="error">无</div>
      </el-image>
      <div class="info">
        <div class="nickname">{{ detail.userInfo.nickname }}</div>
        <div class="nickname">{{ detail.userInfo.mobile }}</div>
        <div class="nickname">
          <el-tag v-if="detail.userInfo.level == 10" type="primary" size="mini">普卡</el-tag>
          <el-tag v-if="detail.userInfo.level == 20" type="primary" size="mini">银卡</el-tag>
          <el-tag v-if="detail.userInfo.level == 30" type="primary" size="mini">金卡</el-tag>
          <el-tag v-if="detail.userInfo.level == 40" type="primary" size="mini">黑卡</el-tag>
          <el-tag style="margin-left: 20px" v-if="detail.userInfo.sex == 1" type="primary" size="mini">男</el-tag>
          <el-tag style="margin-left: 20px" v-if="detail.userInfo.sex == 2" type="primary" size="mini">女</el-tag>
          <el-tag style="margin-left: 20px" v-if="detail.userInfo.sex == 3" type="primary" size="mini">未知</el-tag>
        </div>
      </div>
    </div>
    <div class="content">
      <el-descriptions direction="vertical" :column="4" border>
        <el-descriptions-item label="订单号">{{ detail.orderNo }}</el-descriptions-item>
        <el-descriptions-item label="订单类型">
          <el-tag v-if="detail.orderType == 1" size="mini" type="primary">储值</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="状态" :span="2">{{ detail.statusName }}</el-descriptions-item>
        <el-descriptions-item label="支付方式">
          {{ detail.payTypeName }}
        </el-descriptions-item>
        <el-descriptions-item label="订单金额">{{ detail.orderMoney }}</el-descriptions-item>
        <el-descriptions-item label="支付时间">{{ detail.payTime }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.wrapper {
  .userinfo {
    display: flex;
    padding: 0 20px;
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
    }
  }
  .content {
    padding: 20px;
  }
}
</style>
