<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" inline :model="searchData" label-width="68px">
      <el-form-item label="昵称">
        <el-input style="width: 240px" clearable v-model="searchData.nickname" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input style="width: 240px" clearable v-model="searchData.mobile" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="充值时间">
        <el-date-picker
          v-model="searchData.timeSlot"
          style="width: 240px"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          range-separator="-"
          size="small"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="支付方式">
        <el-select style="width: 240px" v-model="searchData.payType" placeholder="请选择" size="small" clearable>
          <el-option label="支付宝" :value="1"> </el-option>
          <el-option label="微信" :value="2"> </el-option>
          <el-option label="银行卡" :value="3"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付状态">
        <el-select style="width: 240px" v-model="searchData.status" placeholder="请选择" size="small" clearable>
          <el-option label="待支付" :value="0"> </el-option>
          <el-option label="支付成功" :value="1"> </el-option>
          <el-option label="支付失败" :value="2"> </el-option>
          <el-option label="取消支付" :value="3"> </el-option>
          <el-option label="超时" :value="4"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单编号">
        <el-input style="width: 240px" clearable v-model="searchData.orderNo" size="small" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="id" label="用户信息" min-width="180">
        <template slot-scope="{ row }">
          <div class="user-box" v-if="row.userInfo">
            <div class="row">
              <span>ID：</span>
              <el-link type="primary" @click="$router.push({ name: 'CustomerList', query: { id: row.userInfo.id } })">{{ row.userInfo.id }}</el-link>
            </div>
            <div class="row">
              <span>昵称：</span>
              <span>{{ row.userInfo.nickname }}</span>
            </div>
            <!-- <div class="row">
              <span>姓名：</span>
              <span>{{ row.userInfo.realname }}</span>
            </div> -->
            <div class="row">
              <span>手机号：</span>
              <span>{{ row.userInfo.mobile }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="orderNo" label="订单编号" min-width="180"></el-table-column>
      <el-table-column prop="payMoney" label="充值金额" min-width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="payMoney" label="萝卜币" min-width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="payTypeName" label="支付方式" min-width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="transactionId" label="支付流水号" min-width="150"></el-table-column>
      <el-table-column prop="statusName" label="状态" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status == 1" type="success" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status == 2" type="danger" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status == 3" type="info" size="mini">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status == 4" type="primary" size="mini">{{ row.statusName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="充值时间" min-width="160" show-overflow-tooltip></el-table-column>
      <el-table-column prop="id" label="操作" width="120" show-overflow-tooltip fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin: 0 10px" type="primary" :underline="false" @click="handleDetail(row)">详情 </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 详情弹窗 -->
    <el-drawer size="45%" :visible.sync="show_detail" direction="rtl">
      <Detail :detail="detail"></Detail>
    </el-drawer>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, getDetailAPI } from './api'
import Detail from './detail.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { Detail },
  data() {
    return {
      show_detail: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        nickname: '',
        payType: '',
        orderNo: '',
        mobile: '',
        status: '',
        timeSlot: []
      },
      total: 0,
      detail: {}
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {
    // this.getList()
  },

  methods: {
    async getList() {
      const res = await getListAPI(this.searchData)
      this.list = res.records
      this.total = res.totalRow
    },
    // 详情
    async handleDetail({ id }) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
    },
    reset() {
      // this.timeSlot = []
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .user-box {
    .row {
      display: flex;
      span {
        &:nth-child(1) {
          width: 60px;
          text-align: right;
        }
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
}
</style>
