import { http } from '@/http/axios.js'

// 列表
export function getListAPI(data) {
  return http({
    url: `/manage/order/list`,
    method: 'post',
    data
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/manage/order/detail`,
    method: 'get',
    params: { id }
  })
}
